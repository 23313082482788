import "./App.css";

import { Routes, Route, Outlet, Link } from "react-router-dom";

import Dashboard from "./pages/dashboard";
import TutorsLanding from "./pages/landing/tutors";
import StudentsLanding from "./pages/landing/students";

function App() {
  return (
    <Routes>
      <Route path="/" element={<TutorsLanding />} />
      <Route path="test-formula" element={<Dashboard />} />
      <Route path="students" element={<StudentsLanding />} />
      <Route path="*" element={<TutorsLanding />} />
    </Routes>
  );
}

export default App;
