import { Routes, Route, Outlet, Link } from "react-router-dom";
import logo from "../../assets/logo.svg";
import studentsBg from "../../assets/studentsBg.jpeg";
import diagLn from "../../assets/diagLn.svg";

export default function TutorsLanding() {
  return (
    <div className="App-header bg-white">
      <nav className="flex bg-primary-100 text-black border-b-2 border-black h-100 justify-center py-4 items-center">
        <div className="flex container justify-between">
          <div className="flex flex-row items-center justify-between">
            <img src={logo} className="h-6" alt="logo" />
            <div className="flex w-212 h-52 ml-4 border-black border-2 rounded-md">
              <div className="flex flex-1 items-center justify-center m-1 bg-white rounded-md">Students</div>
              <div className="flex flex-1 items-center justify-center m-1 bg-transparent rounded-md">Tutors</div>
            </div>
          </div>
          <div className="flex flex-row items-center gap-10">
            <span className="text-md cursor-pointer">Learning</span>
            <span className="text-md cursor-pointer">Community</span>
            <span className="text-md cursor-pointer">About</span>
            <span className="text-md cursor-pointer">Login</span>
            <button className="py-2 px-8 rounded-lg bg-primary-200 border-2 border-black text-black cursor-pointer">Sign Up</button>
          </div>
        </div>
      </nav>
      <div className="flex justify-center items-center bg-primary-100 border-b-2 border-black h-628">
        <div className="flex flex-col items-center">
          <h1 className="text-5xl font-medium text-black first-letter mb-60">Your Best Digital Resource for the SAT(R)</h1>
          <p className="text-medium text-gray-900 max-w-3xl text-center mb-40">Find ever increasing list of practice tests curated by industry leading tutors. You can track your progress, identify room for improvement and confidently take your SAT with no hassel.</p>
          <button className="py-4 px-12 rounded-lg bg-primary-200 border-2 border-black text-black">Start Now</button>
        </div>
      </div>
      <div className="bg-primary-100 border-b-2 border-black h-1000 relative">
        <img src={studentsBg} className="h-full w-full object-cover scale-x-[-1] border-b-2 border-black" />
        <div className="flex absolute flex-col right-40 top-0 h-full justify-between pb-28">
          <div className="flex h-24 rounded-full bg-white px-4 py-2 items-center justify-between z-30 -mt-4">
            <div className="flex justify-center items-center h-12 w-12 text-2xl text-gray-900 border-2 border-black rounded-full bg-white">A</div>
            <span className="text-2xl text-black px-9">Assessments</span>
          </div>
          <div className="flex h-24 rounded-full bg-white px-4 py-2 items-center justify-between z-30 -rotate-12">
            <div className="flex justify-center items-center h-12 w-12 text-2xl text-gray-900 border-2 border-black rounded-full bg-white">B</div>
            <span className="text-2xl text-black px-9">Homework</span>
          </div>
          <div className="flex h-24 rounded-full bg-white px-4 py-2 items-center justify-between z-30 rotate-12">
            <div className="flex justify-center items-center h-12 w-12 text-2xl text-gray-900 border-2 border-black rounded-full bg-white">C</div>
            <span className="text-2xl text-black px-9">Analytics</span>
          </div>
          <div className="flex h-24 rounded-full bg-white px-4 py-2 items-center justify-between z-30 -rotate-45">
            <div className="flex justify-center items-center h-12 w-12 text-2xl text-gray-900 border-2 border-black rounded-full bg-white">D</div>
            <span className="text-2xl text-black px-9">Practice</span>
          </div>
        </div>
      </div>
      <div className="flex justify-center items-left h-1519 border-b-2 border-black">
        <div className="flex flex-col items-center">
          <div className="flex flex-col w-628 bg-white text-black pt-14 mb-36">
            <span className="text-lg">Why Assessiv?</span>
            <h2 className="text-2xl">Preparation is key</h2>
            <p className="text-gray-500">The Blue Book (R) app just doesn't have enough resources for students to thoroughly prepare; besides, why would the test-writers tell you how to crush the SAT?</p>
          </div>
          <img src={diagLn} alt="" srcset="" />
        </div>
      </div>
      {/* <Link className="text-black" to="/tutors">Tutors</Link>
        <Link className="text-black" to="/students">Students</Link> */}
    </div>
  );
}
