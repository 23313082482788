import logo from "../../assets/logo.svg";
import rtArrow from "../../assets/rt-arrow.svg";

import { useState, useEffect } from "react";
import EquationEditor from "equation-editor-react";

export default function Dashboard() {
  const [equation, setEquation] = useState("y=x");

  useEffect(() => {
    console.log(equation);
  }, [equation]);

  return (
    <div className="App-header bg-white">
      <div className="flex px-2 sm:px-6 lg:px-8 z-10 shadow justify-between py-4 items-center">
        <div className="flex flex-row items-center">
          <img src={logo} className="h-6" alt="logo" />
          <div className="flex flex-row gap-2 ml-8">
            <button className="py-2 px-4 rounded-lg bg-emerald-600 text-gray-50">Home</button>
            <button className="py-2 px-4 rounded-lg hover:bg-emerald-100 text-gray-600">Browse</button>
            <button className="py-2 px-4 rounded-lg hover:bg-emerald-100 text-gray-600">History</button>
          </div>
        </div>
      </div>
      <div className="flex justify-center items-start bg-gray-100 flex-1">
        <div className="container flex flex-col">
          <div className="flex flex-row gap-2 mt-8 items-center text-gray-600 text-sm">
            <div className="">Admin</div>
            <img src={rtArrow} className="h-4" alt="" />
            <div className="">Create New</div>
          </div>
          <span className="text-xl mt-2 text-black">New Question</span>
          <div className="bg-white border border-gray-200 rounded-md shadow-sm mt-5 text-black font-medium">
            <EquationEditor className="font-sans" value={equation} onChange={setEquation} autoCommands="pi theta sqrt sum prod alpha beta gamma rho" autoOperatorNames="sin cos tan" />
          </div>
        </div>
      </div>
    </div>
  );
}
